import Bayer from 'pages/bayer';
import Catalog from 'pages/catalog';
import Pdf from 'components/Pdf';
import Zoely from 'pages/zoely';
import {Route, Switch} from 'react-router-dom';
import Instructions from 'pages/zoely/overlays/Instructions';
import Video from 'pages/video';
import Nomak from 'pages/zoely/overlays/Nomak';
import Effects from 'pages/zoely/overlays/Effects';
import Pharm from 'pages/zoely/overlays/Pharm';
import Venous from 'pages/zoely/overlays/Venous';
import Treatment from 'pages/zoely/overlays/Treatment';
import {Diecyclen, DiecyclenInstruction, DiecyclenPaper} from 'pages/diecyclen';
import {
  Velledien,
  Unus,
  Duo,
  Tres,
  Quattuor,
  Quinque,
  VelledienInstruction,
  VelledienLinkSix,
  VelledienLinkEight, VelledienLinkNine, VelledienLinkSeven, VelledienLinkEleven, VelledienLinkTen
} from './pages/velledien';
import {Vidora, VidoraInstruction, VidoraMicroInstruction} from './pages/vidora';
import {VidoraLink} from './pages/vidora/subpages';
import {MicroginonInstruction, MicroginonLinkOne, MicroginonLinkSecond, Microginon} from './pages/microginon';
import {
  Proginova,
  ProginovaInstruction, ProginovaLinkEight, ProginovaLinkFive, ProginovaLinkFour, ProginovaLinkNine,
  ProginovaLinkOne, ProginovaLinkSeven, ProginovaLinkSix,
  ProginovaLinkThree,
  ProginovaLinkTwo
} from './pages/proginova';
import {
  ProginovaSecond, ProginovaSecondLinkFive, ProginovaSecondLinkFour,
  ProginovaSecondLinkOne,
  ProginovaSecondLinkThree,
  ProginovaSecondLinkTwo
} from './pages/proginova-second';
import {
  Klomonorm,
  KlomonormInstruction,
  KlomonormLinkEight,
  KlomonormLinkFive,
  KlomonormLinkFour,
  KlomonormLinkNine,
  KlomonormLinkOne, KlomonormLinkSeven,
  KlomonormLinkSix,
  KlomonormLinkThree,
  KlomonormLinkTwo,
} from './pages/klomonorm';
import {
  Alvovizan,
  AlvovizanInstruction,
  AlvovizanLinkFive,
  AlvovizanLinkFour,
  AlvovizanLinkOne,
  AlvovizanLinkSix,
  AlvovizanLinkThree,
  AlvovizanLinkTwo
} from './pages/alvovizan';
import {
  AlvovizanSecond, AlvovizanSecondLinkEight, AlvovizanSecondLinkEleven, AlvovizanSecondLinkFive,
  AlvovizanSecondLinkFour,
  AlvovizanSecondLinkNine,
  AlvovizanSecondLinkOne,
  AlvovizanSecondLinkSeven,
  AlvovizanSecondLinkSix,
  AlvovizanSecondLinkTen,
  AlvovizanSecondLinkThirteen,
  AlvovizanSecondLinkThree, AlvovizanSecondLinkTwelve, AlvovizanSecondLinkTwo
} from './pages/alvovizan-second';
import {
  AlvovizanThirteen, AlvovizanThirteenLinkEight, AlvovizanThirteenLinkFive, AlvovizanThirteenLinkFour,
  AlvovizanThirteenLinkOne, AlvovizanThirteenLinkSeven, AlvovizanThirteenLinkSix,
  AlvovizanThirteenLinkThree,
  AlvovizanThirteenLinkTwo
} from './pages/alvovizan-thirteen';
import {ZoelyCompare, ZoelyEffects, ZoelyMissEffects} from './pages/zoely/subpages';
import {
  EffectiveSales,
  EffectiveSalesDoctor,
  EffectiveSalesVisitsAprilJulyDydrogesterone,
  EffectiveSalesVisitsAprilJulyProginova
} from './pages/effective-sales';
import {EffectiveSalesScenarios} from './pages/effective-sales/effective-sales-scenarios';
import {Promotions, SiriuszHelper} from './pages/promotions';
import {DoctorsClubs} from './pages/doctors-clubs';
import {Lactacyd} from './pages/lactacyd';
import {EffectiveSalesVisitsAprilJulyAlvovizan} from './pages/effective-sales/visits-april-july';

export default () => {
  return (
    <Switch>
      <Route
        exact
        path={'/product/eoeli/instruction'}
        component={() => <Instructions/>}
      />
      <Route exact path={'/product/eoeli/nomak'} component={() => <Nomak/>}/>
      <Route
        exact
        path={'/product/eoeli/effects'}
        component={() => <Effects/>}
      />
      <Route exact path={'/product/eoeli/pharm'} component={() => <Pharm/>}/>
      <Route
        exact
        path={'/product/eoeli/venous'}
        component={() => <Venous/>}
      />
      <Route
        exact
        path={'/product/eoeli/treatment'}
        component={() => <Treatment/>}
      />

      <Route
        path={'/prezentation/:type/:name/:id/:forceVertical'}
        component={() => <Pdf/>}
      />
      <Route path={'/video/:type/:id'} component={() => <Video/>}/>
      <Route exact path={'/product/eoeli'} component={() => <Zoely/>}/>
      <Route exact path={'/product/zoely/compare'} component={() => <ZoelyCompare/>}/>
      <Route exact path={'/product/zoely/effects'} component={() => <ZoelyEffects/>}/>
      <Route exact path={'/product/zoely/miss-effects'} component={() => <ZoelyMissEffects/>}/>
      <Route exact path={'/product/alvovizan-thirteen'} component={() => <AlvovizanThirteen/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link1'} component={() => <AlvovizanThirteenLinkOne/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link2'} component={() => <AlvovizanThirteenLinkTwo/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link3'} component={() => <AlvovizanThirteenLinkThree/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link4'} component={() => <AlvovizanThirteenLinkFour/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link5'} component={() => <AlvovizanThirteenLinkFive/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link6'} component={() => <AlvovizanThirteenLinkSix/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link7'} component={() => <AlvovizanThirteenLinkSeven/>}/>
      <Route exact path={'/product/alvovizan-thirteen/link8'} component={() => <AlvovizanThirteenLinkEight/>}/>
      <Route exact path={'/product/alvovizan-second'} component={() => <AlvovizanSecond/>}/>
      <Route exact path={'/product/alvovizan-second/link1'} component={() => <AlvovizanSecondLinkOne/>}/>
      <Route exact path={'/product/alvovizan-second/link2'} component={() => <AlvovizanSecondLinkTwo/>}/>
      <Route exact path={'/product/alvovizan-second/link3'} component={() => <AlvovizanSecondLinkThree/>}/>
      <Route exact path={'/product/alvovizan-second/link4'} component={() => <AlvovizanSecondLinkFour/>}/>
      <Route exact path={'/product/alvovizan-second/link5'} component={() => <AlvovizanSecondLinkFive/>}/>
      <Route exact path={'/product/alvovizan-second/link6'} component={() => <AlvovizanSecondLinkSix/>}/>
      <Route exact path={'/product/alvovizan-second/link7'} component={() => <AlvovizanSecondLinkSeven/>}/>
      <Route exact path={'/product/alvovizan-second/link8'} component={() => <AlvovizanSecondLinkEight/>}/>
      <Route exact path={'/product/alvovizan-second/link9'} component={() => <AlvovizanSecondLinkNine/>}/>
      <Route exact path={'/product/alvovizan-second/link10'} component={() => <AlvovizanSecondLinkTen/>}/>
      <Route exact path={'/product/alvovizan-second/link11'} component={() => <AlvovizanSecondLinkEleven/>}/>
      <Route exact path={'/product/alvovizan-second/link12'} component={() => <AlvovizanSecondLinkTwelve/>}/>
      <Route exact path={'/product/alvovizan-second/link13'} component={() => <AlvovizanSecondLinkThirteen/>}/>
      <Route exact path={'/product/alvovizan/instruction'} component={() => <AlvovizanInstruction/>}/>
      <Route exact path={'/product/alvovizan'} component={() => <Alvovizan/>}/>
      <Route exact path={'/product/alvovizan/link1'} component={() => <AlvovizanLinkOne/>}/>
      <Route exact path={'/product/alvovizan/link2'} component={() => <AlvovizanLinkTwo/>}/>
      <Route exact path={'/product/alvovizan/link3'} component={() => <AlvovizanLinkThree/>}/>
      <Route exact path={'/product/alvovizan/link4'} component={() => <AlvovizanLinkFour/>}/>
      <Route exact path={'/product/alvovizan/link5'} component={() => <AlvovizanLinkFive/>}/>
      <Route exact path={'/product/alvovizan/link6'} component={() => <AlvovizanLinkSix/>}/>
      <Route exact path={'/product/klomonorm'} component={() => <Klomonorm/>}/>
      <Route exact path={'/product/klomonorm/instruction'} component={() => <KlomonormInstruction/>}/>
      <Route exact path={'/product/klomonorm/link1'} component={() => <KlomonormLinkOne/>}/>
      <Route exact path={'/product/klomonorm/link2'} component={() => <KlomonormLinkTwo/>}/>
      <Route exact path={'/product/klomonorm/link3'} component={() => <KlomonormLinkThree/>}/>
      <Route exact path={'/product/klomonorm/link4'} component={() => <KlomonormLinkFour/>}/>
      <Route exact path={'/product/klomonorm/link5'} component={() => <KlomonormLinkFive/>}/>
      <Route exact path={'/product/klomonorm/link6'} component={() => <KlomonormLinkSix/>}/>
      <Route exact path={'/product/klomonorm/link7'} component={() => <KlomonormLinkSeven/>}/>
      <Route exact path={'/product/klomonorm/link8'} component={() => <KlomonormLinkEight/>}/>
      <Route exact path={'/product/klomonorm/link9'} component={() => <KlomonormLinkNine/>}/>
      <Route exact path={'/product/proginova-second/link1'} component={() => <ProginovaSecondLinkOne/>}/>
      <Route exact path={'/product/proginova-second/link2'} component={() => <ProginovaSecondLinkTwo/>}/>
      <Route exact path={'/product/proginova-second/link3'} component={() => <ProginovaSecondLinkThree/>}/>
      <Route exact path={'/product/proginova-second/link4'} component={() => <ProginovaSecondLinkFour/>}/>
      <Route exact path={'/product/proginova-second/link5'} component={() => <ProginovaSecondLinkFive/>}/>
      <Route exact path={'/product/proginova-second'} component={() => <ProginovaSecond/>}/>
      <Route exact path={'/product/proginova/instruction'} component={() => <ProginovaInstruction/>}/>
      <Route exact path={'/product/proginova/link1'} component={() => <ProginovaLinkOne/>}/>
      <Route exact path={'/product/proginova/link2'} component={() => <ProginovaLinkTwo/>}/>
      <Route exact path={'/product/proginova/link3'} component={() => <ProginovaLinkThree/>}/>
      <Route exact path={'/product/proginova/link4'} component={() => <ProginovaLinkFour/>}/>
      <Route exact path={'/product/proginova/link5'} component={() => <ProginovaLinkFive/>}/>
      <Route exact path={'/product/proginova/link6'} component={() => <ProginovaLinkSix/>}/>
      <Route exact path={'/product/proginova/link7'} component={() => <ProginovaLinkSeven/>}/>
      <Route exact path={'/product/proginova/link8'} component={() => <ProginovaLinkEight/>}/>
      <Route exact path={'/product/proginova/link9'} component={() => <ProginovaLinkNine/>}/>
      <Route exact path={'/product/proginova'} component={() => <Proginova/>}/>
      <Route exact path={'/product/microginon/link1'} component={() => <MicroginonLinkOne/>}/>
      <Route exact path={'/product/microginon/link2'} component={() => <MicroginonLinkSecond/>}/>
      <Route exact path={'/product/microginon/instruction'} component={() => <MicroginonInstruction/>}/>
      <Route exact path={'/product/microginon'} component={() => <Microginon/>}/>
      <Route exact path={'/product/vidora-micro/instruction'} component={() => <VidoraMicroInstruction/>}/>
      <Route exact path={'/product/vidora/link'} component={() => <VidoraLink/>}/>
      <Route exact path={'/product/vidora/instruction'} component={() => <VidoraInstruction/>}/>
      <Route exact path={'/product/vidora'} component={() => <Vidora/>}/>
      <Route exact path={'/product/velledien/link1'} component={() => <Unus/>}/>
      <Route exact path={'/product/velledien/link2'} component={() => <Duo/>}/>
      <Route exact path={'/product/velledien/link3'} component={() => <Tres/>}/>
      <Route exact path={'/product/velledien/link4'} component={() => <Quattuor/>}/>
      <Route exact path={'/product/velledien/link5'} component={() => <Quinque/>}/>
      <Route exact path={'/product/velledien/link6'} component={() => <VelledienLinkSix/>}/>
      <Route exact path={'/product/velledien/link7'} component={() => <VelledienLinkSeven/>}/>
      <Route exact path={'/product/velledien/link8'} component={() => <VelledienLinkEight/>}/>
      <Route exact path={'/product/velledien/link9'} component={() => <VelledienLinkNine/>}/>
      <Route exact path={'/product/velledien/link10'} component={() => <VelledienLinkTen/>}/>
      <Route exact path={'/product/velledien/link11'} component={() => <VelledienLinkEleven/>}/>
      <Route exact path={'/product/velledien/instruction'} component={() => <VelledienInstruction/>}/>
      <Route exact path={'/product/velledien'} component={() => <Velledien/>}/>
      <Route exact path={'/product/diecyclen/link'} component={() => <DiecyclenPaper/>}/>
      <Route exact path={'/product/diecyclen/instruction'} component={() => <DiecyclenInstruction/>}/>
      <Route exact path={'/product/diecyclen'} component={() => <Diecyclen/>}/>
      <Route path={'/product/lactacyd'} component={() => <Lactacyd/>}/>
      <Route exact path={'/promotions'} component={() => <Promotions/>}/>
      <Route exact path={'/promotions/siriusz-helper'} component={() => <SiriuszHelper/>}/>
      <Route path={'/doctors-clubs'} component={() => <DoctorsClubs/>}/>
      <Route exact path={'/effective-sales/visits-april-july-proginova'} component={() => <EffectiveSalesVisitsAprilJulyProginova/>}/>
      <Route exact path={'/effective-sales/visits-april-july-alvovizan'} component={() => <EffectiveSalesVisitsAprilJulyAlvovizan/>}/>
      <Route exact path={'/effective-sales/visits-april-july-dydrogesterone'} component={() => <EffectiveSalesVisitsAprilJulyDydrogesterone/>}/>
      <Route path={'/effective-sales'} component={() => <EffectiveSales/>}/>
      <Route path={'/effective-sales-doctor'} component={() => <EffectiveSalesDoctor/>}/>
      <Route path={'/effective-sales-scenarios'} component={() => <EffectiveSalesScenarios/>}/>
      <Route path={'/bayer'} component={() => <Bayer/>}/>
      <Route path={'/'} component={() => <Catalog/>}/>
    </Switch>
  );
};
