import {Block, LI, LIB, StyledBoxBorder, StyledBoxShadow, UL, H1, Span, Sup} from '../ui';
import {Ticket} from './ticket';
import {Box} from '@mui/material';
import women from './img/women.png'

export const LactacydPharma = () => {
  return (
    <StyledBoxShadow sx={{mb: '150px'}}>
      <Box>
        <H1>LACTACYD® PHARMA</H1>
        <Box sx={{marginTop: -3, fontSize: 24, color: '#F9D2D6'}}>С АНТИБАКТЕРИАЛЬНЫМИ КОМПОНЕНТАМИ</Box>
      </Box>

      <Box sx={{display: 'flex', gap: '20px', mb: 5, mt: 3}}>
        <Block>при беременности</Block>
        <Block>во время менструаций</Block>
        <Block>на каждый день</Block>
      </Box>


      <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>

        <img src={women} alt="lactacyd" style={{maxWidth: '100%'}}/>

        <StyledBoxBorder>
          <Ticket title="Факторы риска нарушения первой защиты">
            <Box sx={{mb: 2}}>
              Вульва является первой линией защиты половых путей от инфекции<Sup>1</Sup>. Микрофлора вульвы может влиять на
              размножение экзогенных патогенов, вызывающих инфекции урогенитальных путей и неприятный запах<Sup>1</Sup>.
            </Box>

            <Box sx={{mb: 2}}>
              Различные факторы могут влиять на рН вульвы и, соответственно, на микрофлору вульвы<Sup>1</Sup>:
            </Box>

            <UL>
              <LI sx={{mb: 2}}>
                <Span sx={{color: '#F9D2D6'}}>эндогенные:</Span> повышенная влажность, пот, выделения из влагалища,
                менструация, загрязнение мочой и калом,
                анатомическая складчатость, генетика и возраст.
              </LI>
              <LI>
                <Span sx={{color: '#F9D2D6'}}>экзогенные:</Span> мыло, моющие средства, косметические продукты,
                смазочные вещества и спермициды, продукты
                для бритьяи депиляции, трение плотной одеждой или гигиеническими прокладками.
              </LI>
            </UL>

          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder>
          <Ticket title="Менструации">
            <Box sx={{mb: 2}}>
              Во время «ранней фолликулярной» или «менструальной» фазы цикла (1—5 дни) уровни 17β-эстрадиола и
              прогестерона находятся на самом низком уровне, что способствует повышению pH интимной зоны, нарушению
              микрофлоры влагалища, сопровождающемуся снижением численности лактобактерий, повышением микробного
              разнообразия (в частности, условно-патогенной флоры). Возникает риск бактериальных инфекций.
            </Box>

            <Box>
              Недостаточная гигиена во время менструации повышает риск не только рецидивирующих инфекций
              урогенитального тракта, но и воспалительных заболеваний органов малого таза, синдрома токсического
              шока
              и рака шейкиматки<Sup>5,14</Sup>.
            </Box>

          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder>
          <Ticket title="Беременность">
            <Box sx={{mb: 2}}>
              Известно, что не всегда беременность протекает гладко. Под воздействием ряда факторов: снижение
              местного иммунитета, приём некоторых препаратов, неправильная гигиена – может развиваться нарушение
              баланса микрофлоры во влагалище, уменьшаться количество лактобактерий.
            </Box>

            <Box sx={{mb: 2}}>
              Во время беременности необходимо устранять факторы риска, которые могут привести к дисбиозу
              вагинальной микрофлоры. Дисбиоз вагинальной микрофлоры повышает восприимчивость к развитию
              воспалительных заболеваний половых путей. Эти инфекции могут быть занесены из влагалища восходящим
              путём или через кровь из негенитальных источников – например, при инфекционно-воспалительных
              заболеваниях ротовой полости<Sup>9—11</Sup>.
            </Box>

            <Box sx={{mb: 2}}>
              Инфекции могут привести к преждевременному началу родов, преждевременному разрыву плодного пузыря,
              преэклампсии, выкидышу, ограничению роста плода,низкому весу при рождении и неонатальному
              сепсису<Sup>8—12</Sup>.
            </Box>

            <Box sx={{mb: 2}}>
              В исследовании у женщин с бактериурией и вагинитами эрадикация бактерий снизила частоту низкого веса
              при рождении в 2 раза, преждевременных родов в 3 раза, преэклампсии – в 2 раза по сравнению с
              женщинами, у которых не была проведена эрадикация бактерий<Sup>7</Sup>.
            </Box>

            <Box sx={{mb: 2}}>
              Поэтому важно, чтобы интимная гигиена для беременных женщин не только соответствовала высоким
              стандартам качества, но и обеспечивала бережное очищение и защиту входныхворот в женский
              урогенитальный тракт.
            </Box>

          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder>
          <Ticket
            title="Средство Лактацид® Фарма с антибактериальными компонентами с pH 3.5 специально разработано для защиты от бактериальных инфекций">
            <Box sx={{mb: 2}}>
              Средство LACTACYD® PHARMA содержит экстракт тимьяна, обладающий антибактериальным действием, и
              активную (протонированную) форму натуральной молочной кислоты, оказывающую подавляющее действие на ряд
              вирусов и бактерий, вызывающих урогенитальные инфекции6. Молочная кислота способствует поддержанию
              роста полезных лактобактерий, повышает естественную защиту интимной зоны, способствует снижению риска
              инфекций, борется с ростом условнопатогенных бактерий, подавляет размножение болезнетворных микробов,
              укрепляет барьерные функции кожи и слизистых интимной зоны, обладает иммуномодулирующим, антимикробным
              и противовоспалительным действием<Sup>2—4</Sup>.
            </Box>
          </Ticket>
        </StyledBoxBorder>

        <StyledBoxBorder sx={{backgroundColor: '#F9D2D6'}}>
          <Ticket>
            <Box sx={{mb: 2, color: '#2F2F34'}}>
              <strong>
                LACTACYD PHARMA — дарит ощущение комфорта, чистоты и свежести. Лёгкий травяной аромат понравится даже
                девушкам, которые остро реагируют на разные запахи во время беременности.
              </strong>
            </Box>

            <UL sx={{color: '#2F2F34'}}>
              <LIB sx={{mb: 1}}><strong>Бережное очищение:</strong> мягкие моющие компоненты для очищения слизистой оболочки от загрязнений</LIB>
              <LIB sx={{mb: 1}}><strong>Против микроорганизмов:</strong> активная протонированная форма натуральной молочной кислоты</LIB>
              <LIB sx={{mb: 1}}><strong>pH 3.5 способствует росту лактобактерий</strong> и подавлению патогенов</LIB>
              <LIB sx={{mb: 1}}><strong>Экстракт тимьяна</strong> – противоспалительный, антисептический эффект</LIB>
              <LIB sx={{mb: 1}}><strong>Клинически подтверждённый</strong> бактериостатический эффект<Sup>13</Sup></LIB>
              <LIB sx={{mb: 1}}>Подходит для применения <strong>во время беременности и в период после родов*</strong></LIB>
              <LIB sx={{mb: 1}}>Предназначено <strong>для ежедневного применения</strong></LIB>
            </UL>
          </Ticket>
        </StyledBoxBorder>

        <Box sx={{display: 'flex', gap: '20px', mb: 5, mt: '4px'}}>
          <Block sx={{textAlign: 'center', flex: '1 1 0'}}>
            <Box sx={{color: '#F9D2D6'}}>Стоимость</Box>
            <Box>от 346 ₽</Box>
          </Block>
          <Block sx={{textAlign: 'center', flex: '1 1 0'}}>
            <Box sx={{color: '#F9D2D6'}}>Объём</Box>
            <Box>250 мл</Box>
          </Block>
          <Block sx={{textAlign: 'center', flex: '1 1 0'}}>
            <Box sx={{color: '#F9D2D6'}}>Расход средства:</Box>
            <Box>2-6 мес. применения</Box>
          </Block>
          <Block sx={{textAlign: 'center', flex: '1 1 0'}}>
            <Box sx={{color: '#F9D2D6'}}>Точки продаж:</Box>
            <Box>аптеки, онлайн</Box>
          </Block>
        </Box>
      </Box>

      <Box sx={{fontSize: 12, lineHeight: '16px', mb: 3, mt: 1, pt: 2, borderTop: '1px solid #F9D2D6'}}>
        * Перед применением беременная женщинадолжна проконсультироваться в гинекологом < br/>
        1. Lin YP, Chen WC, Cheng CM, Shen CJ. VaginalpH Value for Clinical Diagnosisand Treatment of Common Vaginitis. Diagnostics (Basel). 2021 Oct 27;11(11):1996. < br/>
        2. Tyssen D, et al. Front ReprodHealth. 2022 Jun 23;4:915948. < br/>
        3. Delgado-Diaz DJ, et al. Microbiome. 2022 Aug 31;10(1):141. < br/>
        4. Plummer EL, et al. PLoS One. 2021 Feb 11;16(2):e0246953. < br/>
        5. Meher, T., Sahoo, H. Dynamics of usage of menstrual hygieneand unhygienic methodsamong young womenin India: a spatial analysis. BMC Women's Health23, 573 (2023). < br/>
        6. O'Hanlon DE, Come RA, Moench TR. Vaginal pH measured in vivo: lactobacilli determine pH and lactic acid concentration. BMC Microbiol. 2019;19(1):13. Published 2019 Jan 14. < br/>
        7. Herrera JA, Chaudhuri G, López-Jaramillo P. Is infection a major risk factor for preeclampsia? Med Hypotheses. 2001 Sep;57(3):393-7. < br/>
        8. Mysorekar IU, Cao B. Microbiome in parturition and preterm birth.Semin Reprod Med. (2014) 32:50–5. < br/>
        9. Wang YL, Liou JD, Pan WL. Association betweenmaternal periodontal diseaseand preterm deliveryand low birthweight. Taiwan J Obstet Gynecol.(2013) 52:71–6. < br/>
        10. Mariano Sanz, Kenneth Kornman, Working group 3 of the joint EFP/AAP workshop. Periodontitis and adverse pregnancy outcomes: consensus report of the joint EFP/AAP workshop on periodontitis and systemic diseases. J Periodontol. (2013)84 (Suppl. 4):S164–9. < br/>
        11. Hongyu Ren, Minquan Du. Role of maternal periodontitis in preterm birth.Front Immunol. (2017)8:139. < br/>
        12. RomeroR, Hassan SS, Gajer P, Tarca AL, Fadrosh DW, Bieda J, et al. The vaginalmicrobiota of pregnantwomen who subsequently have spontaneous pretermlabor and deliveryand those with a normal delivery at term. Microbiome. (2014) 2:18. < br/>
        13. Источник: Микробиологический тест in vitro,август 2013 г., Германия. 14. Kumar G, et al. Assessment of menstrual hygieneamong reproductive age women in South-west Delhi.J Family Med Prim Care.2017 Oct-Dec;6(4):730-734. < br/>
      </Box>

    </StyledBoxShadow>
  )
}
