import {useHistory} from 'react-router-dom'

import Page from 'shared/ui/Page';
import bg from './img/bg.png';
import {styled} from '@mui/system';

export const DoctorsClubs = () => {
  const history = useHistory();
  const handleRedirectToProducts = () => {
    history.push('/');
  }

  return (
    <Page head={'РЕГИОНАЛЬНЫЕ КЛУБЫ ХОРОШИХ ГИНЕКОЛОГОВ'} backText="Продукты" onClose={handleRedirectToProducts}>
      <Img src={bg}/>
      <Wrapper>
        <H1>СКОРО ОТКРЫТИЕ!</H1>
        <Divide></Divide>
        <H2>НОВОСТИ, АНОНСЫ, ЯРКИЕ ФОТО И ВИДЕО-ОТЧЕТЫ О МЕРОПРИЯТИЯХ РЕГИОНАЛЬНЫХ КЛУБОВ ХОРОШИХ ГИНЕКОЛОГОВ</H2>
      </Wrapper>
    </Page>
  )
}

const Img = styled('img')({
  position: 'absolute',
  marginTop: '-110px',
  marginLeft: '-20px',
  zIndex: -1,
  maxWidth: '100%'
})

const Wrapper = styled('div')`
  display: flex;
  height: 560px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #F9D2D6;
  margin: 120px 20px 0px;
  text-align: center;
  box-sizing: border-box;
`;

const Divide = styled('div')`
  width: 200px;
  height: 2px;
  opacity: .6;
  background: #F9D2D6;
`;

const H1 = styled('h1')`
  margin-bottom: 0px;
  color: #F9D2D6;
  text-align: center;
  font-family: SF Pro Text;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
`;

const H2 = styled('h1')`
  margin-top: 0px;
  color: #FFF;
  text-align: center;
  font-family: SF Pro Text;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
`;
